.editor-container {
  border-radius: 2px;
  color: #000;
  position: relative;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.editor-inner {
  background: #fff;
  position: relative;
}
.editor-input,
.editor-input-inline {
  resize: none;
  caret-color: #050505;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
  cursor: text;
}
.editor-input-inline {
  padding: 8px;
}
.editor-placeholder,
.editor-placeholder-inline {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}
.editor-placeholder-inline {
  top: 8px;
  left: 8px;
}
.editor-text-bold {
  font-weight: bold;
}
.editor-text-italic {
  font-style: italic;
}
.editor-text-underline {
  text-decoration: underline;
}
.editor-text-strikethrough {
  text-decoration: line-through;
}
.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}
.editor-text-code {
  background-color: #f0f2f5;
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
  border-radius: 4px;
}
.editor-link {
  color: #216fdb;
  text-decoration: none;
}
.clickable-links .editor-link {
  cursor: pointer;
}
.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}
.editor-code {
  background-color: #f0f2f5;
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  border-radius: 4px;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}
.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}
.editor-tokenComment {
  color: slategray;
}
.editor-tokenPunctuation {
  color: #999;
}
.editor-tokenProperty {
  color: #905;
}
.editor-tokenSelector {
  color: #690;
}
.editor-tokenOperator {
  color: #9a6e3a;
}
.editor-tokenAttr {
  color: #07a;
}
.editor-tokenVariable {
  color: #e90;
}
.editor-tokenFunction {
  color: #dd4a68;
}
.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}
.editor-paragraph:last-child {
  margin-bottom: 0;
}
.editor-heading-h1 {
  font-size: 24px;
  color: #050505;
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}
.editor-heading-h2 {
  font-size: 15px;
  color: #65676b;
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}
.editor-quote {
  margin: 0;
  font-size: 15px;
  color: #65676b;
  border-left-color: #ced0d4;
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}
.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}
.editor-listitem {
  margin: 8px 32px 8px 32px;
}
.editor-nested-listitem {
  list-style-type: none;
}
pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}
pre::-webkit-scrollbar-thumb {
  background: #999;
}
.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}
.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}
.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}
.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}
.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}
.debug-timetravel-button:hover {
  text-decoration: underline;
}
.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}
.emoji-inner {
  padding: 0 0.15em;
}
.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}
.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}
.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}
.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}
.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}
.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}
.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}
.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}
.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}
.toolbar button.toolbar-item.active i {
  opacity: 1;
}
.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}
.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}
.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 6px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}
.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}
.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}
.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}
.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}
.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}
i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(../icons/chevron-down.svg);
}
#block-controls button:hover {
  background-color: #efefef;
}
#block-controls button:focus-visible {
  border-color: blue;
}
#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}
#block-controls span.block-type.paragraph {
  background-image: url(../icons/text-paragraph.svg);
}
#block-controls span.block-type.h1 {
  background-image: url(../icons/type-h1.svg);
}
#block-controls span.block-type.h2 {
  background-image: url(../icons/type-h2.svg);
}
#block-controls span.block-type.quote {
  background-image: url(../icons/chat-square-quote.svg);
}
#block-controls span.block-type.ul {
  background-image: url(../icons/list-ul.svg);
}
#block-controls span.block-type.ol {
  background-image: url(../icons/list-ol.svg);
}
#block-controls span.block-type.code {
  background-image: url(../icons/code.svg);
}
.dropdown {
  z-index: 2000;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}
.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  border: 0;
  min-width: 268px;
}
.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.dropdown .item:first-child {
  margin-top: 8px;
}
.dropdown .item:last-child {
  margin-bottom: 8px;
}
.dropdown .item:hover {
  background-color: #eee;
}
.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}
.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}
.link-editor {
  position: absolute;
  z-index: 2000;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  transition: opacity 0.5s;
}
.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: #050505;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}
.link-editor div.link-edit {
  background-image: url(../icons/pencil-fill.svg);
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.link-editor .link-input a {
  color: #216fdb;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}
.link-editor .link-input a:hover {
  text-decoration: underline;
}
.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 2px;
}
.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}
.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}
.editor-listItemChecked,
.editor-listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.editor-listItemChecked {
  text-decoration: line-through;
}
.editor-listItemUnchecked:before,
.editor-listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.editor-listItemUnchecked.editor-nested-listitem:before,
.editor-listItemChecked.editor-nested-listitem:before {
  display: none;
}
.editor-listItemUnchecked[dir='rtl']:before,
.editor-listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.editor-listItemUnchecked:focus:before,
.editor-listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.editor-listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.editor-listItemChecked:before {
  border: 1px solid #3d87f5;
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.editor-listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.editor-table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  width: 100%;
}
.editor-tableCell {
  border: 1px solid black;
  padding: 6px 8px;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
}
.editor-tableCellHeader {
  text-align: start;
  font-weight: normal;
}
i.undo {
  background-image: url(../icons/arrow-counterclockwise.svg);
}
i.redo {
  background-image: url(../icons/arrow-clockwise.svg);
}
.icon.paragraph {
  background-image: url(../icons/text-paragraph.svg);
}
.icon.large-heading,
.icon.h1 {
  background-image: url(../icons/type-h1.svg);
}
.icon.small-heading,
.icon.h2 {
  background-image: url(../icons/type-h2.svg);
}
.icon.bullet-list,
.icon.ul {
  background-image: url(../icons/list-ul.svg);
}
.icon.numbered-list,
.icon.ol {
  background-image: url(../icons/list-ol.svg);
}
.icon.quote {
  background-image: url(../icons/chat-square-quote.svg);
}
.icon.code {
  background-image: url(../icons/code.svg);
}
i.bold {
  background-image: url(../icons/type-bold.svg);
}
i.italic {
  background-image: url(../icons/type-italic.svg);
}
i.underline {
  background-image: url(../icons/type-underline.svg);
}
i.strikethrough {
  background-image: url(../icons/type-strikethrough.svg);
}
i.code {
  background-image: url(../icons/code.svg);
}
i.link {
  background-image: url(../icons/link.svg);
}
i.left-align {
  background-image: url(../icons/text-left.svg);
}
i.center-align {
  background-image: url(../icons/text-center.svg);
}
i.right-align {
  background-image: url(../icons/text-right.svg);
}
i.justify-align {
  background-image: url(../icons/justify.svg);
}
.link-editor {
  position: absolute;
  z-index: 2000;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  transition: opacity 0.5s;
}
i.palette {
  background-image: url(../icons/palette.svg);
}
i.bucket {
  background-image: url(../icons/paint-bucket.svg);
}
i.bold {
  background-image: url(../icons/type-bold.svg);
}
i.italic {
  background-image: url(../icons/type-italic.svg);
}
i.clear {
  background-image: url(../icons/trash.svg);
}
i.code {
  background-image: url(../icons/code.svg);
}
i.underline {
  background-image: url(../icons/type-underline.svg);
}
i.strikethrough {
  background-image: url(../icons/type-strikethrough.svg);
}
i.subscript {
  background-image: url(../icons/type-subscript.svg);
}
i.superscript {
  background-image: url(../icons/type-superscript.svg);
}
i.link {
  background-image: url(../icons/link.svg);
}
i.horizontal-rule,
.icon.horizontal-rule {
  background-image: url(../icons/horizontal-rule.svg);
}
.icon.plus {
  background-image: url(../icons/plus.svg);
}
.icon.dropdown-more {
  background-image: url(../icons/dropdown-more.svg);
}
.icon.font-color {
  background-image: url(../icons/font-color.svg);
}
.icon.bg-color {
  background-image: url(../icons/bg-color.svg);
}
i.image,
.icon.image {
  background-image: url(../icons/file-image.svg);
}
.icon.table {
  background-image: url(../icons/table.svg);
}
i.poll {
  background-image: url(../icons/card-checklist.svg);
}
i.tweet {
  background-image: url(../icons/tweet.svg);
}
i.youtube {
  background-image: url(../icons/youtube.svg);
}
.icon.left-align,
i.left-align {
  background-image: url(../icons/text-left.svg);
}
i.center-align {
  background-image: url(../icons/text-center.svg);
}
i.right-align {
  background-image: url(../icons/text-right.svg);
}
i.justify-align {
  background-image: url(../icons/justify.svg);
}
i.indent {
  background-image: url(../icons/indent.svg);
}
i.markdown {
  background-image: url(../icons/markdown.svg);
}
i.outdent {
  background-image: url(../icons/outdent.svg);
}
i.undo {
  background-image: url(../icons/arrow-counterclockwise.svg);
}
i.redo {
  background-image: url(../icons/arrow-clockwise.svg);
}
i.sticky {
  background-image: url(../icons/sticky.svg);
}
i.mic {
  background-image: url(../icons/mic.svg);
}
i.import {
  background-image: url(../icons/upload.svg);
}
i.export {
  background-image: url(../icons/download.svg);
}
i.diagram-2 {
  background-image: url(../icons/diagram-2.svg);
}
i.user {
  background-image: url(../icons/user.svg);
}
i.equation {
  background-image: url(../icons/plus-slash-minus.svg);
}
i.gif {
  background-image: url(../icons/filetype-gif.svg);
}
i.copy {
  background-image: url(../icons/copy.svg);
}
i.success {
  background-image: url(../icons/success.svg);
}
i.prettier {
  background-image: url(../icons/prettier.svg);
}
i.prettier-error {
  background-image: url(../icons/prettier-error.svg);
}
select.font-size,
select.font-family {
  cursor: pointer;
}
.mention:focus {
  box-shadow: #b4d5ff 0px 0px 0px 2px;
  outline: none;
}
#block-controls {
  display: block;
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  top: 16px;
  z-index: 10;
  border-radius: 6px;
  border: 1px solid #ced0d4;
  overflow: hidden;
}
#block-controls button {
  border: 1px solid white;
  background-color: #fff;
  display: block;
  transition: background-color 0.1s ease;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  padding: 3px;
}
#block-controls button:hover {
  background-color: #efefef;
}
#block-controls button:focus-visible {
  border-color: blue;
}
#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}
#block-controls span.block-type.paragraph {
  background-image: url(../icons/text-paragraph.svg);
}
#block-controls span.block-type.h1 {
  background-image: url(../icons/type-h1.svg);
}
#block-controls span.block-type.h2 {
  background-image: url(../icons/type-h2.svg);
}
#block-controls span.block-type.quote {
  background-image: url(../icons/chat-square-quote.svg);
}
#block-controls span.block-type.ul {
  background-image: url(../icons/list-ul.svg);
}
#block-controls span.block-type.ol {
  background-image: url(../icons/list-ol.svg);
}
#block-controls span.block-type.code {
  background-image: url(../icons/code.svg);
}
.characters-limit {
  color: #888;
  font-size: 12px;
  text-align: right;
  display: block;
  position: absolute;
  left: 12px;
  bottom: 5px;
}
.characters-limit.characters-limit-exceeded {
  color: red;
}
.editor-dropdown {
  z-index: 2000;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}
.editor-dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  border: 0;
  max-width: 250px;
}
.editor-dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.editor-dropdown .item:first-child {
  margin-top: 8px;
}
.editor-dropdown .item:last-child {
  margin-bottom: 8px;
}
.editor-dropdown .item:hover {
  background-color: #eee;
}
.editor-dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}
.editor-dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}
.editor-dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}
.editor-dropdown hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
@media screen and (max-width: 1000px) {
  .editor-dropdown-button-text {
    display: none !important;
  }
}
.icon.paragraph {
  background-image: url(../icons/text-paragraph.svg);
}
.icon.h1 {
  background-image: url(../icons/type-h1.svg);
}
.icon.h2 {
  background-image: url(../icons/type-h2.svg);
}
.icon.h3 {
  background-image: url(../icons/type-h3.svg);
}
.icon.h4 {
  background-image: url(../icons/type-h4.svg);
}
.icon.h5 {
  background-image: url(../icons/type-h5.svg);
}
.icon.h6 {
  background-image: url(../icons/type-h6.svg);
}
.icon.bullet-list,
.icon.bullet {
  background-image: url(../icons/list-ul.svg);
}
.icon.check-list,
.icon.check {
  background-image: url(../icons/square-check.svg);
}
.icon.numbered-list,
.icon.number {
  background-image: url(../icons/list-ol.svg);
}
.icon.quote {
  background-image: url(../icons/chat-square-quote.svg);
}
.icon.code {
  background-image: url(../icons/code.svg);
}
.switches {
  z-index: 6;
  position: fixed;
  left: 10px;
  bottom: 70px;
  animation: slide-in 0.4s ease;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.switch {
  display: block;
  color: #444;
  margin: 5px 0;
  background-color: rgba(238, 238, 238, 0.7);
  padding: 5px 10px;
  border-radius: 6px;
}
#rich-text-switch {
  right: 0;
}
#character-count-switch {
  right: 130px;
}
.switch label {
  margin-right: 5px;
  line-height: 24px;
  width: 100px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}
.switch button {
  background-color: #ced0d4;
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  width: 44px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s;
  border: 2px solid transparent;
}
.switch button:focus-visible {
  border-color: blue;
}
.switch button span {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: white;
  transition: transform 0.2s;
}
.switch button[aria-checked='true'] {
  background-color: #1877f2;
}
.switch button[aria-checked='true'] span {
  transform: translateX(20px);
}
.editor-container span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}
.editor-container .editor-image img {
  max-width: 100%;
  border-radius: 4px;
}
.editor-container .editor-image img.focused {
  outline: 2px solid #3c84f4;
  user-select: none;
}
.editor-container .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}
.editor-container .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}
.editor-container .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}
.editor-container .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}
.editor-container .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #3c84f4;
  border: 1px solid #fff;
}
.editor-container .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}
.emoji {
  color: transparent;
  caret-color: #050505;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}
.emoji-inner {
  padding: 0 0.15em;
}
.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}
.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}
.keyword {
  color: #f1765e;
  font-weight: bold;
}
.actions {
  position: relative;
  text-align: right;
  padding: 10px;
}
.actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}
.actions i.indent {
  background-image: url(../icons/indent.svg);
}
.actions i.outdent {
  background-image: url(../icons/outdent.svg);
}
.actions i.lock {
  background-image: url(../icons/lock-fill.svg);
}
.actions i.image {
  background-image: url(../icons/file-image.svg);
}
.actions i.table {
  background-image: url(../icons/table.svg);
}
.actions i.unlock {
  background-image: url(../icons/lock.svg);
}
.actions i.left-align {
  background-image: url(../icons/text-left.svg);
}
.actions i.center-align {
  background-image: url(../icons/text-center.svg);
}
.actions i.right-align {
  background-image: url(../icons/text-right.svg);
}
.actions i.justify-align {
  background-image: url(../icons/justify.svg);
}
.actions i.disconnect {
  background-image: url(../icons/plug.svg);
}
.actions i.connect {
  background-image: url(../icons/plug-fill.svg);
}
.table-cell-action-button-container {
  position: absolute;
}
.table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}
i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(../icons/chevron-down.svg);
}
.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}
.action-button:hover {
  background-color: #ddd;
  color: #000;
}
.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}
button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}
@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }
  50% {
    background-color: #ff8585;
  }
  100% {
    background-color: #ffdcdc;
  }
}
.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}
.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}
.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}
.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}
.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}
.debug-timetravel-button:hover {
  text-decoration: underline;
}
.connecting {
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 10px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}
.ltr {
  text-align: left;
}
.rtl {
  text-align: right;
}
.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: 36px;
}
.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
}
.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}
.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}
.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}
.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}
.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}
.toolbar button.toolbar-item.active i {
  opacity: 1;
}
.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}
.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 6px;
  padding: 8px;
  padding-right: 24px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}
.toolbar select.code-language {
  width: 150px;
}
.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
  padding-right: 10px;
}
.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}
.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}
.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}
.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}
.sticky-note-container {
  position: absolute;
  z-index: 9;
  width: 120px;
  display: inline-block;
}
.sticky-note {
  line-height: 1;
  text-align: left;
  width: 120px;
  margin: 25px;
  padding: 20px 10px;
  position: relative;
  border: 1px solid #e8e8e8;
  font-family: 'Reenie Beanie';
  font-size: 24px;
  border-bottom-right-radius: 60px 5px;
  display: block;
  cursor: move;
}
.sticky-note:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: 0px;
  bottom: 20px;
  width: 120px;
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
  transform: matrix(-1, -0.1, 0, 1, 0, 0);
}
.sticky-note.yellow {
  border-top: 1px solid #fdfd86;
  background: linear-gradient(135deg, #ffff88 81%, #ffff88 82%, #ffff88 82%, #ffffc6 100%);
}
.sticky-note.pink {
  border-top: 1px solid #e7d1e4;
  background: linear-gradient(135deg, #f7cbe8 81%, #f7cbe8 82%, #f7cbe8 82%, #e7bfe1 100%);
}
.sticky-note-container.dragging {
  transition: none !important;
}
.sticky-note div {
  cursor: text;
}
.sticky-note .delete {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 10px;
  cursor: pointer;
  opacity: 0.5;
}
.sticky-note .delete:hover {
  font-weight: bold;
  opacity: 1;
}
.sticky-note .color {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 25px;
  cursor: pointer;
  opacity: 0.5;
}
.sticky-note .color:hover {
  opacity: 1;
}
.sticky-note .color i {
  display: block;
  width: 12px;
  height: 12px;
  background-size: contain;
}
.floating-text-format-popup {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  z-index: 2000;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  transition: opacity 0.5s;
  height: 35px;
}
.floating-text-format-popup button.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}
.floating-text-format-popup button.popup-item:disabled {
  cursor: not-allowed;
}
.floating-text-format-popup button.popup-item.spaced {
  margin-right: 2px;
}
.floating-text-format-popup button.popup-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}
.floating-text-format-popup button.popup-item:disabled i.format {
  opacity: 0.2;
}
.floating-text-format-popup button.popup-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}
.floating-text-format-popup button.popup-item.active i {
  opacity: 1;
}
.floating-text-format-popup .popup-item:hover:not([disabled]) {
  background-color: #eee;
}
.floating-text-format-popup select.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}
.floating-text-format-popup select.code-language {
  text-transform: capitalize;
  width: 130px;
}
.floating-text-format-popup .popup-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}
.floating-text-format-popup .popup-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}
.floating-text-format-popup i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}
.floating-text-format-popup i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}
.floating-text-format-popup .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}
.excalidraw-button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.excalidraw-button.selected {
  outline: 2px solid #3c84f4;
  user-select: none;
}
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
.spacer {
  letter-spacing: -2px;
}
button.item i {
  opacity: 0.6;
}
button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}
button.item.dropdown-item-active i {
  opacity: 1;
}
#typeahead-menu {
  position: fixed;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  z-index: 3;
}
#typeahead-menu ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: scroll;
}
#typeahead-menu ul::-webkit-scrollbar {
  display: none;
}
#typeahead-menu ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#typeahead-menu ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}
#typeahead-menu ul li.selected {
  background: #eee;
}
#typeahead-menu li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border: 0;
  max-width: 250px;
}
#typeahead-menu li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}
#typeahead-menu li:first-child {
  border-radius: 6px 8px 0px 0px;
}
#typeahead-menu li:last-child {
  border-radius: 0px 0px 8px 8px;
}
#typeahead-menu li:hover {
  background-color: #eee;
}
#typeahead-menu li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}
#typeahead-menu li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}
.editor-mention {
  padding: 4px 4px 0 4px;
  border-radius: 4px;
  background-color: #f0f3f8;
}
.floating-text-format-popup {
  padding: 0;
}
.editor-image-contentEditable {
  min-height: 20px;
  border: 0px;
  resize: none;
  cursor: text;
  caret-color: #050505;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0px;
  padding: 10px;
  user-select: text;
  font-size: 12px;
  width: calc(100% - 20px);
  white-space: pre-wrap;
  word-break: break-word;
}
.editor-image-placeholder {
  font-size: 12px;
  color: #888;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 10px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}
.image-control-wrapper--resizing {
  touch-action: none;
}
.editor-container span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}
.editor-container .editor-image img {
  max-width: 100%;
  border-radius: 4px;
}
.editor-container .editor-image img.focused {
  outline: 2px solid #3c84f4;
  user-select: none;
}
.editor-container .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}
.editor-container .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}
.editor-container .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}
.editor-container .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}
.editor-container .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #3c84f4;
  border: 1px solid #fff;
}
.editor-container .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}
.editor-container .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}
